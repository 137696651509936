<script setup>
import getRoute from '@/Common/GetRoute.js';
import SiteHeader from '@/Layouts/Partials/SiteHeader.vue';
import DropdownLink from '@/Components/Dropdown/DropdownLink.vue';
import SiteContainer from '@/Layouts/Partials/SiteContainer.vue';
import Banner from '@/Components/Banner.vue';
import NavBarItem from '@/Components/Nav/NavBarItem.vue';
import { Head, usePage } from '@inertiajs/vue3';
import Dropdown from '@/Components/Dropdown/Dropdown.vue';
import Footer from '@/Layouts/Partials/Footer.vue';
import NavBar from '@/Components/Nav/NavBar.vue';
import { reactive } from 'vue';

defineProps({
  title: String,
  description: {
    type: String,
    default: ''
  }
});

const state = reactive({
  mobileMenuVisible: false
});

const errors = Object.values(usePage().props.errors ?? {});
const activeRoute = (route_) => Boolean(route().current(route_));
</script>

<template>
  <Head>
    <title>{{ title }}</title>
    <meta
      v-if="description.length"
      head-key="description"
      name="description"
      :content="description"
    />
  </Head>
  <Banner
    v-if="errors.length"
    :style="'danger'"
    :message="errors.join('<br>')"
  />
  <SiteContainer>
    <SiteHeader
      @toggle-mobile-menu="(isOpen) => (state.mobileMenuVisible = isOpen)"
    />
    <NavBar :class="{ hidden: !state.mobileMenuVisible }">
      <NavBarItem :href="getRoute('score')" :active="activeRoute('score.*')">
        Know Your Score
      </NavBarItem>
      <NavBarItem
        :href="getRoute('domain-analysis')"
        :active="activeRoute('domain-analysis.*')"
      >
        Domain Analysis
      </NavBarItem>
      <Dropdown
        align="middle"
        :active="activeRoute('lookup*') || activeRoute('explain.*')"
      >
        <template #trigger>Lookup</template>
        <template #content>
          <DropdownLink :href="getRoute('lookup', ['dns'])">DNS</DropdownLink>
          <DropdownLink :href="route('lookup.blacklist.index')">
            Blacklist Lookup
          </DropdownLink>
        </template>
      </Dropdown>
      <NavBarItem
        :href="route('header-analysis.index')"
        :active="activeRoute('header-analysis.index')"
      >
        Header Analysis
      </NavBarItem>
      <NavBarItem
        :href="route('spf-policy-test.index')"
        :active="activeRoute('spf-policy-test.*')"
      >
        SPF Policy Test
      </NavBarItem>
      <Dropdown
        align="right"
        :active="activeRoute('cidr.*') || activeRoute('dkim-generator.*')"
      >
        <template #trigger>More Tools</template>
        <template #content>
          <DropdownLink :href="route('cidr.index')">
            CIDR Calculator
          </DropdownLink>
          <DropdownLink :href="route('dkim-generator.index')">
            DKIM Key Generator
          </DropdownLink>
        </template>
      </Dropdown>
    </NavBar>
    <div class="mt-8 flex flex-col gap-4 md:gap-8">
      <slot />
    </div>
    <Footer />
  </SiteContainer>
</template>
