<script setup>
import Card from '@/Components/Card/Card.vue';
import { inject } from 'vue';
import ExplainScorePart from '@/Pages/DomainScore/Partials/DetailedReport/ExplainScorePart.vue';
import ActionType from '@/Common/Enum/ActionType.js';
import { API_STATUS } from '@/Common/Enum/ApiStatus.js';
import { Link } from '@inertiajs/vue3';
import LinkDomainAnalysis from '@/Pages/DomainScore/Partials/DetailedReport/LinkDomainAnalysis.vue';
import DetailedReportHeader from '@/Pages/DomainScore/Partials/DetailedReport/DetailedReportHeader.vue';

const result = inject('score');

const tags =
  result.records.spf?.tags?.reduce((acc, tag) => {
    acc[tag.msg] = tag;
    return acc;
  }, {}) ?? {};

const lookupMessage = (() => {
  const tagMessages = {
    recursive: {
      message: 'Recursive Lookups found',
      actionType: ActionType.ACTION_REQUIRED
    },
    'dns>10': {
      message: `Too many DNS Lookups`,
      actionType: ActionType.ACTION_REQUIRED
    },
    'mxa>10': {
      message: `Too many MX A Records`,
      actionType: ActionType.ACTION_REQUIRED
    },
    'void>2': {
      message: `Too many VOID Lookups`,
      actionType: ActionType.ACTION_RECOMMENDED
    },
    'dns>6': {
      message: `High DNS Lookups`,
      actionType: ActionType.ACTION_RECOMMENDED
    },
    'void>0': {
      message: `High VOID Lookups`,
      actionType: ActionType.ACTION_RECOMMENDED
    }
  };

  let actionType = null;
  let messages = [];

  Object.keys(tags).forEach((tag) => {
    if (tagMessages.hasOwnProperty(tag)) {
      if (actionType === null) {
        actionType = tagMessages[tag].actionType;
      }
      messages.push(tagMessages[tag].message);
    }
  });

  if (!actionType) {
    return {
      actionType: ActionType.NO_ACTION,
      message: `${result.records.spf.num_lookup} Lookups found`
    };
  }

  return {
    actionType,
    message:
      `${result.records.spf.num_lookup} Lookups found (` +
      messages.join('. ') +
      ')'
  };
})();

const allMessage = (() => {
  const tagMessages = {
    '~': {
      message:
        "'~all' Set to soft fail authentication of emails from senders that are not authorized and treat them as suspicious",
      actionType: ActionType.NO_ACTION
    },
    '-': {
      message:
        "'-all' Set to fail authentication of email from senders that are not authorized",
      actionType: ActionType.NO_ACTION
    },
    '?': {
      message:
        "'?all' Set to Neutral, which neither passes nor fails authentication of emails from senders that are not authorized.",
      actionType: ActionType.ACTION_REQUIRED
    },
    '+': {
      message:
        "'+all' Set to pass authentication of emails from senders that are not authorized",
      actionType: ActionType.ACTION_REQUIRED
    }
  };

  if (tagMessages.hasOwnProperty(result.records.spf.all_qualifier)) {
    return tagMessages[result.records.spf.all_qualifier];
  }

  return {
    message:
      "Not Specified, set '+all' to pass authentication of emails from senders that are not authorized.",
    actionType: ActionType.ACTION_REQUIRED
  };
})();
</script>

<template>
  <Card class="bg-white-opacity">
    <DetailedReportHeader
      title="SPF"
      description="Sender Policy Framework"
      tooltip="The SPF record contains a list of mail servers which you have authorized to send email on your behalf."
    />
    <div>
      <div class="mt-5 flex flex-col">
        <ExplainScorePart
          v-if="
            tags.hasOwnProperty('no spf no mx') &&
            !result.impersonation.has_spf_record
          "
          title="DNS Record"
          :action-type="ActionType.INFO"
        >
          No MX records. SPF not required.
        </ExplainScorePart>
        <ExplainScorePart
          v-else-if="!result.impersonation.has_spf_record"
          title="DNS Record"
          :action-type="ActionType.NO_ACTION"
        >
          Record not found
        </ExplainScorePart>
        <ExplainScorePart
          v-else-if="tags.hasOwnProperty('Too many records')"
          title="DNS Record"
          :action-type="ActionType.ACTION_REQUIRED"
        >
          Multiple SPF records found -
          <LinkDomainAnalysis :domain="result.domain" fragment="spf" />
        </ExplainScorePart>
        <ExplainScorePart
          v-else-if="
            [API_STATUS.FAIL, API_STATUS.FATAL].includes(
              result.records.spf.status
            )
          "
          title="DNS Record"
          :action-type="ActionType.ACTION_REQUIRED"
        >
          Invalid record found -
          <LinkDomainAnalysis :domain="result.domain" fragment="spf" />
        </ExplainScorePart>
        <ExplainScorePart
          v-else-if="result.records?.spf?.status === API_STATUS.ERROR"
          title="DNS Record"
          :action-type="ActionType.ACTION_REQUIRED"
        >
          Record found with errors -
          <Link
            class="text-blue-400"
            :href="route('domain-analysis.show', [result.domain])"
          >
            Analyze
          </Link>
        </ExplainScorePart>
        <ExplainScorePart
          v-else-if="result.records?.spf?.status === API_STATUS.INFO"
          title="DNS Record"
          :action-type="ActionType.ACTION_RECOMMENDED"
        >
          Record found with warnings -
          <LinkDomainAnalysis :domain="result.domain" fragment="spf" />
        </ExplainScorePart>
        <ExplainScorePart
          v-else
          title="DNS Record"
          :action-type="ActionType.NO_ACTION"
        >
          Record found without errors -
          <LinkDomainAnalysis :domain="result.domain" fragment="spf" />
        </ExplainScorePart>
        <div
          v-if="result.records?.spf?.status !== API_STATUS.FATAL"
          class="flex flex-col"
        >
          <ExplainScorePart
            title="Lookups"
            :action-type="lookupMessage.actionType"
          >
            {{ lookupMessage.message }}
          </ExplainScorePart>

          <ExplainScorePart title="All" :action-type="allMessage.actionType">
            {{ allMessage.message }}
          </ExplainScorePart>
        </div>
      </div>
    </div>
  </Card>
</template>
