<script setup>
import Card from '@/Components/Card/Card.vue';
import ExpandablePill from '@/Components/ExpandablePill.vue';
import StatusPill from '@/Pages/Common/StatusPill.vue';
import { inject, reactive } from 'vue';
import { getActionTypeFromStatus } from '@/Common/Enum/ActionType.js';
import StatusIcon from '@/Pages/Common/StatusIcon.vue';
import Preloader from '@/Components/Preloader.vue';
import ExplainDkimRecord from '@/Pages/DomainAnalysis/Partials/ExplainDkimRecord.vue';
import axios from 'axios';
import ActionType from '@/Common/Enum/ActionType.js';
import { DKIM as DKIMTags } from '@/Common/Classifiers/TagMaps.js';
import ExplainTitle from '@/Pages/DomainAnalysis/Partials/ExplainTitle.vue';
import ExplainDirectiveCard from '@/Pages/DomainAnalysis/Partials/ExplainDirectiveCard.vue';
import ExplainDirectivePart from '@/Pages/DomainAnalysis/Partials/ExplainDirectivePart.vue';

const domain = inject('domain');
const record = inject('result').records.dkim;

const state = reactive({
  result: {}
});

const expand = async (selector) => {
  if (!state.result.hasOwnProperty(selector)) {
    state.result[selector] = (
      await axios.get(route('explain.dkim', { domain, selector }))
    ).data;
  }
};

const tags = Array.from(DKIMTags.map(record.tags).values()).filter(
  (t) => t?.pill != null
);
</script>

<template>
  <Card>
    <ExplainTitle :pills="tags" title="DKIM">
      <StatusPill v-if="record.num_dkim_records" :action-type="ActionType.INFO">
        {{ record.num_dkim_records }} Selectors
      </StatusPill>
    </ExplainTitle>

    <div v-if="record.selectors?.length" class="mt-2 flex flex-col gap-2">
      <ExpandablePill
        v-for="(selector, index) in record.selectors"
        :key="index"
        @click="expand(selector.selector)"
      >
        <template #default="{ expanded }">
          <div class="flex flex-1 justify-between">
            <div class="flex" :class="{ truncate: !expanded }">
              <div class="pr-2 font-bold">{{ selector.selector }}</div>
              <div
                :class="{
                  'text-pretty break-all whitespace-normal': expanded,
                  truncate: !expanded
                }"
                class="font-mono"
              >
                {{ selector.entries[0] }}
              </div>
            </div>
            <div class="pl-4">
              <StatusIcon
                :action-type="getActionTypeFromStatus(selector.status)"
              />
            </div>
          </div>
        </template>

        <template #expandable>
          <ExplainDirectiveCard>
            <Preloader
              v-if="!state.result.hasOwnProperty(selector.selector)"
              :rows="7"
            />
            <ExplainDkimRecord
              v-else
              :record="state.result[selector.selector]"
            />
          </ExplainDirectiveCard>
          <div class="flex flex-col gap-2 p-4 text-sm md:px-8">
            <ExplainDirectivePart
              title="Selector"
              :directive="{
                checks: [],
                value: selector.selector
              }"
            />
            <ExplainDirectivePart
              title="Key Length"
              :directive="{
                checks: [],
                value: selector.key_length
              }"
            />
            <ExplainDirectivePart
              v-for="(cname, idx) in state.result[selector.selector]?.record
                .cnames"
              :key="idx"
              title="CNAME"
              :directive="{
                checks: [],
                value: cname.cname
              }"
            >
              <template #detail>
                <p>
                  <a
                    class="text-sm text-blue-600 underline hover:text-blue-500"
                    :href="route('explain.dkim', [domain, selector.selector])"
                  >
                    Lookup
                  </a>
                </p>
              </template>
            </ExplainDirectivePart>
          </div>
        </template>
      </ExpandablePill>
    </div>
  </Card>
</template>
