<script setup>
import SiteLayout from '@/Layouts/Layout.vue';
import Card from '@/Components/Card/Card.vue';
import TextAreaInput from '@/Components/Input/TextAreaInput.vue';
import { reactive, ref, watch } from 'vue';
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import AuthenticationSummary from '@/Pages/HeaderAnalysis/Partials/AuthenticationSummary.vue';
import EmailHops from '@/Pages/HeaderAnalysis/Partials/EmailHops.vue';
import AllHeaders from '@/Pages/HeaderAnalysis/Partials/AllHeaders.vue';
import Alert from '@/Components/Alert.vue';
import AntispamHeaders from '@/Pages/HeaderAnalysis/Partials/AntispamHeaders.vue';
import FileUploadInput from '@/Components/Input/FileUploadInput.vue';
import Summary from '@/Pages/HeaderAnalysis/Partials/Summary.vue';
import AuthenticationResults from '@/Pages/HeaderAnalysis/Partials/AuthenticationResults.vue';
import ReceivedSPF from '@/Pages/HeaderAnalysis/Partials/ReceivedSPF.vue';
import DKIMSignatures from '@/Pages/HeaderAnalysis/Partials/DKIMSignatures.vue';
import AuthenticatedReceiveChain from '@/Pages/HeaderAnalysis/Partials/AuthenticatedReceiveChain.vue';
import OutlinedButton from '@/Components/Button/OutlinedButton.vue';

const state = reactive({
  header: '',
  errors: false,
  result: null,
  isLoading: false
});

const createAnalysis = async (header) => {
  state.result = null;
  try {
    state.isLoading = true;
    state.error = false;
    state.result = (
      await axios.post(route('header-analysis.create'), { header })
    ).data;
  } catch {
    state.error = true;
  }
  state.isLoading = false;
};

const processUploadedFile = (file) => {
  const reader = new FileReader();

  reader.onload = function (event) {
    state.header = event.target.result;
    document.activeElement.blur();
    createAnalysis(state.header);
  };

  reader.readAsText(file.files[0]);
};

const onPaste = (event) => {
  createAnalysis(event.clipboardData.getData('text/plain'));
};

const analyseHeaders = () => {
  createAnalysis(state.header);
};
const reset = () => {
  state.header = '';
  state.result = null;
  state.errors = false;
};

const collapsed = ref(false);

watch(state, () => {
  collapsed.value = !!state.header;
});
</script>

<template>
  <SiteLayout
    title="Email Header Analysis Tool"
    description="Analyse Email Headers information such as Header Hop Details and Authentication Results with Sendmarc Tools."
  >
    <Card>
      <div class="flex flex-col gap-4">
        <FileUploadInput
          :collapsed="collapsed"
          drop-text="Drop File to Analyze Header"
          label="Upload Email File"
          :file-types="['txt', 'eml', 'emlx', 'ics', 'mbox']"
          :size-limit-kb="2048"
          @files-added="processUploadedFile"
        />
        <div
          class="flex items-end gap-4"
          :class="{
            'flex-row': collapsed
          }"
        >
          <TextAreaInput
            v-model.trim="state.header"
            label="Paste Email Source Code"
            col="20"
            :rows="collapsed ? 1 : 6"
            :collapsed="collapsed"
            @paste="onPaste"
            @focus="collapsed = false"
            @clear="reset"
            :is-loading="state.isLoading"
            class="grow basis-auto"
          />
        </div>

        <div class="flex gap-4" v-if="!collapsed">
          <PrimaryButton
            :is-loading="state.isLoading"
            size="large"
            @click="analyseHeaders"
          >
            Analyze Header
          </PrimaryButton>

          <OutlinedButton
            v-if="state.header"
            class="border-blue-500! text-blue-500!"
            @click="reset()"
          >
            Clear
          </OutlinedButton>
        </div>

        <Alert
          v-if="state.error"
          variant="danger"
          title="An unknown error has occurred"
        />
      </div>
    </Card>
    <div v-if="state.result?.summary" class="flex flex-col gap-6">
      <div class="flex flex-col-reverse md:flex-row md:gap-8">
        <Summary
          class="w-2/3"
          :summary="state.result.summary"
          :sender="state.result.sender"
        />
        <AuthenticationSummary class="grow" :auth="state.result.summary.auth" />
      </div>

      <EmailHops :hops="state.result.hops" />
      <AuthenticationResults
        :results="state.result.auth_headers.auth_results"
      />
      <ReceivedSPF :received-spf="state.result.auth_headers.received_spf" />
      <DKIMSignatures
        :dkim-signatures="state.result.auth_headers.dkim_signatures"
      />
      <AuthenticatedReceiveChain
        :arc-results="state.result.auth_headers.authenticated_receive_chain"
      />
      <AntispamHeaders :antispam-headers="state.result.antispam_headers" />
      <AllHeaders :headers="state.result.all_headers" />
    </div>
  </SiteLayout>
</template>
