<script setup>
import RatingSquareScore from '@/Pages/DomainScore/Partials/RatingSquareScore.vue';
import RiskLabel from '@/Pages/DomainScore/Partials/RiskLabel.vue';
import DmarcCard from '@/Pages/DomainScore/Partials/DetailedReport/Impersonation/DmarcCard.vue';
import SpfCard from '@/Pages/DomainScore/Partials/DetailedReport/Impersonation/SpfCard.vue';
import DkimCard from '@/Pages/DomainScore/Partials/DetailedReport/Impersonation/DkimCard.vue';
import { inject } from 'vue';
import ACTION_TYPES, {
  getActionTypeFromScore
} from '@/Common/Enum/ActionType.js';
import Help from '@/Components/Help.vue';

const result = inject('score');
const actionType = getActionTypeFromScore(result.impersonation.score);
</script>

<template>
  <div>
    <div class="flex items-start gap-3">
      <div class="flex flex-col items-center">
        <RatingSquareScore :score="result.impersonation.score" />
        <RiskLabel
          :score="result.impersonation.score"
          class="mt-1 text-sm text-nowrap"
        >
          Risk
        </RiskLabel>
      </div>
      <div class="flex flex-col gap-2">
        <h5 class="flex items-center gap-2 text-lg font-bold text-white">
          Impersonation Rating
          <Help
            text="Your impersonation rating shows the likelihood of your domain being used in impersonation attacks. We check your DNS settings to ensure they're correctly set up to defend against attacks like this."
          />
        </h5>
        <p v-if="actionType === ACTION_TYPES.NO_ACTION">
          Your organization has very low risk of cybercriminals using your
          domain to send fake emails. Domains with a rating of 5 are 100% DMARC
          compliant and are protected from impersonation and phishing attacks.
        </p>
        <p v-else-if="actionType === ACTION_TYPES.ACTION_RECOMMENDED">
          One more change is needed to fully protect your domain. Savvy
          cybercriminals could still impersonate your business and stakeholders.
          Deliverability of legitimate email may be disrupted and some messages
          may land in Spam or Junk folders.
        </p>
        <p v-else>
          Your domain currently has little to no protection, leaving it
          vulnerable to cybercriminal use in email-based attacks. This could
          lead to financial loss, decreased trust and reputational damage.
        </p>
      </div>
    </div>
    <div class="mt-4 flex flex-col gap-2 md:gap-4">
      <DmarcCard />
      <SpfCard />
      <DkimCard />
    </div>
  </div>
</template>
