<script setup>
import { computed } from 'vue';
import StatusIcon from '@/Pages/Common/StatusIcon.vue';
import { NightcrawlerCheckClassifier } from '@/Common/Classifiers/NightcrawlerCheckClassifier.js';

const { directive, title, classifier } = defineProps({
  title: String,
  directive: Object,
  classifier: {
    type: NightcrawlerCheckClassifier,
    required: true
  }
});

const classification = classifier.get(
  directive?.directive,
  directive?.status,
  directive?.checks,
  directive?.value
);

const tooltip = computed(() => {
  return (
    classification?.errorMessages?.join('\n') ??
    classification.actionType.tooltip
  );
});
</script>

<template>
  <div
    v-if="directive && classification"
    class="flex flex-col gap-1 md:flex-row md:gap-8"
  >
    <div class="flex justify-between font-bold md:w-1/3">
      <div>{{ title }}</div>
      <div class="hidden md:block">{{ classification.directive }}</div>
    </div>
    <div class="flex flex-1 justify-between">
      <div
        class="text-wrap break-all"
        :class="{
          'text-cool-grey-800 font-light italic': classification.isDefault
        }"
      >
        {{ classification.message }}
      </div>
      <div class="pl-4">
        <slot v-if="$slots.default"></slot>
        <StatusIcon
          v-else
          v-tooltip="tooltip"
          :action-type="classification.actionType"
        />
      </div>
    </div>
  </div>
</template>
