<script setup>
import SiteLayout from '@/Layouts/Layout.vue';
import SpfPolicyTestForm from '@/Pages/SpfPolicyTest/Partials/SpfPolicyTestForm.vue';
import Card from '@/Components/Card/Card.vue';
import SpfTree from '@/Pages/SpfPolicyTest/Partials/SpfTree.vue';
import ipRangeCheck from 'ip-range-check';
import StatusIcon from '@/Pages/Common/StatusIcon.vue';
import ACTION_TYPES from '@/Common/Enum/ActionType.js';

const props = defineProps({
  email: String,
  domain: String,
  ip: String,
  result: Object
});

const isIpInRange =
  ipRangeCheck(props.ip, props.result.ip4_ranges) ||
  ipRangeCheck(props.ip, props.result.ip6_ranges);
</script>

<template>
  <SiteLayout
    title="SPF Policy Test Tool"
    description="Test whether or not a particular IP address is present in an SPF record with Sendmarc Tools."
  >
    <SpfPolicyTestForm :email="email" :ip="ip" />
    <Card>
      <div class="flex flex-col gap-3">
        <div class="flex items-center justify-between md:justify-start">
          <div class="text-sm font-bold md:w-3/12">IP is permitted sender</div>
          <div class="text-sm">
            <StatusIcon
              :action-type="
                isIpInRange
                  ? ACTION_TYPES.NO_ACTION
                  : ACTION_TYPES.ACTION_REQUIRED
              "
            />
          </div>
        </div>

        <div class="flex items-center justify-between md:justify-start">
          <div class="text-sm font-bold md:w-3/12">SPF Policy</div>
          <div class="text-sm">
            <StatusIcon
              :action-type="
                result.record_status
                  ? ACTION_TYPES.NO_ACTION
                  : ACTION_TYPES.ACTION_REQUIRED
              "
            />
          </div>
        </div>

        <div class="flex items-center justify-between md:justify-start">
          <div class="text-sm font-bold md:w-3/12">IP</div>
          <div class="text-sm">{{ ip }}</div>
        </div>

        <div class="flex items-center justify-between md:justify-start">
          <div class="text-sm font-bold md:w-3/12">Sender</div>
          <div class="text-sm">{{ email }}</div>
        </div>

        <div class="flex items-center justify-between md:justify-start">
          <div class="text-sm font-bold md:w-3/12">Domain</div>
          <div class="text-sm">{{ domain }}</div>
        </div>
      </div>
      <Card class="bg-white-smoke-500 mt-8">
        <div class="treeview js-treeview">
          <ul>
            <li>
              <SpfTree :node="result.spf_tree" :ip="ip" />
            </li>
          </ul>
        </div>
      </Card>
    </Card>
  </SiteLayout>
</template>
