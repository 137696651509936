<script setup>
import CardLabel from '@/Components/Card/CardLabel.vue';
import Card from '@/Components/Card/Card.vue';
import { ToggleTracker } from '@/Common/utils.js';
import IconExpandCircleDown from '@/Components/Icons/IconExpandCircleDown.vue';
import PropertyListItem from '@/Pages/HeaderAnalysis/Partials/PropertyListItem.vue';

defineProps({
  antispamHeaders: Array
});

const toggler = new ToggleTracker();
</script>

<template>
  <div>
    <CardLabel label="Antispam Headers" />
    <Card>
      <div v-if="0 === antispamHeaders.length">No Results</div>
      <div v-else class="flex flex-col gap-2">
        <div class="flex gap-6">
          <div class="w-1/6 font-bold">Field</div>
          <div class="w-1/3 font-bold">Value</div>
          <div class="w-1/3 font-bold">Description</div>
          <div class="w-1/12 font-bold"><!-- action col --></div>
        </div>
        <template v-for="(header, index) in antispamHeaders" :key="index">
          <div
            class="flex cursor-pointer items-center gap-6"
            @click="toggler.toggle(index)"
          >
            <div class="w-1/6">{{ header.field }}</div>
            <div class="w-1/3 truncate">{{ header.value }}</div>
            <div class="w-1/3 grow">{{ header.description }}</div>
            <div class="flex w-1/12 justify-end">
              <IconExpandCircleDown
                class="text-navy-200 self-end transition-transform duration-300"
                :class="{ '-rotate-180': toggler.isToggled(index) }"
              />
            </div>
          </div>
          <Card
            v-if="toggler.isToggled(index)"
            class="bg-white-smoke-500!"
            :pad-bottom="true"
          >
            <div class="flex w-1/2 flex-col">
              <PropertyListItem title="Field" :value="header.field" />
              <PropertyListItem title="Value" :value="header.value" />
              <PropertyListItem
                title="Description"
                :value="header.description"
              />
              <PropertyListItem
                v-if="header.explanation"
                title="Explanation"
                :value="header.explanation"
              />
            </div>
          </Card>
        </template>
      </div>
    </Card>
  </div>
</template>
