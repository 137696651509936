<script setup>
import SiteLayout from '@/Layouts/Layout.vue';
import Card from '@/Components/Card/Card.vue';
import { computed, reactive, watch } from 'vue';
import TextInput from '@/Components/Input/TextInput.vue';
import Alert from '@/Components/Alert.vue';
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import Select from '@/Components/Select.vue';
import CopyButton from '@/Components/Button/CopyButton.vue';
import isValidDkimSelector from '@/Common/IsValidDkimSelector.js';

const state = reactive({
  domain: '',
  dkimSelector: '',
  keySize: 1024,
  data: null,
  isDkimSelectorValid: true
});

const cname = computed(
  () => `${state.dkimSelector}._domainkey.${state.domain}`
);

watch(
  () => state.dkimSelector,
  (newValue) => {
    state.isDkimSelectorValid = isValidDkimSelector(newValue);
  }
);

const dkimRecord = computed(() => {
  let publicKey = state.data.public
    .replace('-----BEGIN PUBLIC KEY-----', '')
    .replace('-----END PUBLIC KEY-----', '')
    .replace(/\s/g, '');
  return `v=DKIM1;t=s;p=${publicKey}`;
});

async function generateDkimKeys() {
  if (!state.isDkimSelectorValid) {
    return;
  }
  state.data = null;

  const keyPair = await crypto.subtle.generateKey(
    {
      name: 'RSA-OAEP',
      modulusLength: state.keySize,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: 'SHA-256'
    },
    true,
    ['encrypt', 'decrypt']
  );

  const publicKey = await crypto.subtle.exportKey('spki', keyPair.publicKey);
  const publicKeyPem = convertArrayBufferToPem(publicKey, 'PUBLIC KEY');

  const privateKey = await crypto.subtle.exportKey('pkcs8', keyPair.privateKey);
  const privateKeyPem = convertArrayBufferToPem(privateKey, 'PRIVATE KEY');

  state.data = {
    private: privateKeyPem,
    public: publicKeyPem
  };
}

function convertArrayBufferToPem(buffer, type, lineLength = 64) {
  const base64String = btoa(String.fromCharCode(...new Uint8Array(buffer)));

  const chunkedBase64 = base64String
    .match(new RegExp(`.{1,${lineLength}}`, 'g'))
    .join('\n');

  return `-----BEGIN ${type}-----\n${chunkedBase64}\n-----END ${type}-----\n`;
}
</script>

<template>
  <SiteLayout
    title="DKIM Key Generator"
    description="Generate DKIM keys easily with our free online DKIM Key Generator. Create secure public and private key pairs for email authentication and protection against spoofing."
  >
    <Card>
      <form @submit.prevent="generateDkimKeys">
        <div class="flex flex-col justify-between gap-4 md:flex-row">
          <TextInput
            v-model.trim="state.domain"
            size="large"
            label="DKIM Domain"
            description="e.g. example.com"
            clearable
            required
          />

          <TextInput
            v-model.trim="state.dkimSelector"
            size="large"
            :variant="state.isDkimSelectorValid ? 'default' : 'danger'"
            label="DKIM Selector"
            :description="
              state.isDkimSelectorValid
                ? 'e.g. myselector'
                : 'DKIM selector is not valid'
            "
            clearable
            required
          />
          <Select v-model="state.keySize" size="large" label="Key Size">
            <option>1024</option>
            <option>2048</option>
          </Select>

          <div class="flex md:items-center md:justify-center">
            <PrimaryButton
              size="large"
              :is-loading="state.isLoading"
              class="mt-2"
            >
              Generate DKIM Record
            </PrimaryButton>
          </div>
        </div>
      </form>
    </Card>

    <Card v-if="state.data">
      <div class="flex flex-col gap-6">
        <Alert
          v-if="state.data"
          title="DKIM Record Generated"
          variant="success"
        >
          Please store this information safely as we do NOT retain a copy of it
          and we cannot regenerate the same key.
        </Alert>

        <div>
          <div class="font-bold">Private Key</div>
          <div class="mb-2">Enter this key into your DKIM Signer</div>
          <div class="bg-navy-400 relative rounded-xl p-6 text-sm text-white">
            <CopyButton
              class="absolute top-6 right-6"
              :copy-text="state.data.private"
            />
            {{ state.data.private }}
          </div>
        </div>

        <div>
          <div class="font-bold">DKIM Record</div>
          <div class="mb-2">Publish this DNS TXT record on {{ cname }}</div>
          <div
            class="bg-navy-400 relative overflow-auto rounded-xl p-6 text-sm break-words text-white"
          >
            <CopyButton
              class="absolute top-6 right-6"
              :copy-text="dkimRecord"
            />
            {{ dkimRecord }}
          </div>
        </div>

        <div>
          <div class="font-bold">Public Key</div>
          <div class="mb-2">
            This is the public key in the original raw "X509" format.
          </div>
          <div class="bg-navy-400 relative rounded-xl p-6 text-sm text-white">
            <CopyButton
              class="absolute top-6 right-6"
              :copy-text="state.data.public"
            />
            {{ state.data.public }}
          </div>
        </div>
      </div>
    </Card>
  </SiteLayout>
</template>
