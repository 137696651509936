<script setup>
import IconContentCopy from '@/Components/Icons/IconContentCopy.vue';
import { reactive } from 'vue';
import IconCheckCircle from '@/Components/Icons/IconCheckCircle.vue';

const props = defineProps({
  copyText: {
    type: String,
    required: true
  }
});

const state = reactive({
  copied: false
});

const copy = () => {
  state.copied = true;
  navigator.clipboard.writeText(props.copyText);
  setTimeout(() => {
    state.copied = false;
  }, 2500);
};
</script>

<template>
  <div
    class="bg-white-smoke-500 rounded-xl p-2 transition duration-200"
    :class="{ 'hover:bg-white-smoke-700': !state.copied }"
    @click="copy"
  >
    <IconContentCopy v-if="!state.copied" class="text-cool-grey-500" />
    <div v-else class="flex items-end justify-between gap-2">
      <div class="text-parakeet-500 font-bold">Copied</div>
      <IconCheckCircle class="text-parakeet-500" />
    </div>
  </div>
</template>
