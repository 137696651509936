<script setup>
import RatingSquareScore from '@/Pages/DomainScore/Partials/RatingSquareScore.vue';
import RiskLabel from '@/Pages/DomainScore/Partials/RiskLabel.vue';
import { inject } from 'vue';
import TlsCard from '@/Pages/DomainScore/Partials/DetailedReport/Privacy/TlsCard.vue';
import MtaStsCard from '@/Pages/DomainScore/Partials/DetailedReport/Privacy/MtaStsCard.vue';
import Help from '@/Components/Help.vue';
import ACTION_TYPES, {
  getActionTypeFromScore
} from '@/Common/Enum/ActionType.js';

const result = inject('score');
const actionType = getActionTypeFromScore(result.privacy.score);
</script>

<template>
  <div class="flex items-start gap-3">
    <div class="flex flex-col items-center">
      <RatingSquareScore :score="result.privacy.score" />
      <RiskLabel :score="result.privacy.score" class="mt-1 text-sm text-nowrap">
        Risk
      </RiskLabel>
    </div>
    <div class="flex flex-col gap-2">
      <h5 class="flex items-center gap-2 text-lg font-bold text-white">
        Privacy Rating
        <Help
          text="Your privacy rating looks at whether you are monitoring and enforcing that your mail server providers have been setup correctly to receive TLS-secured SMTP connections."
        />
      </h5>
      <p v-if="actionType === ACTION_TYPES.NO_ACTION">
        Your emails are at low risk of being seen or intercepted by
        cybercriminals. Your domain has strong encryption standards and you'll
        get reports on how well these are being upheld.
      </p>
      <p v-else-if="actionType === ACTION_TYPES.ACTION_RECOMMENDED">
        You need to tighten up your email encryption. If it's not secure, your
        emails could be intercepted.
      </p>
      <p v-else>
        Your domain has minimal to no defenses in place for email privacy.
        There's a high risk of your communications being intercepted or
        compromised by unauthorized users. While most senders encrypt their
        communications by default, it's critical to enforce policies for those
        that don't.
      </p>
    </div>
  </div>
  <div class="mt-4 flex flex-col gap-2 md:gap-4">
    <TlsCard />
    <MtaStsCard />
  </div>
</template>
