<script setup>
import getButtonSizeClasses from '@/Components/Button/sizing.js';
import IconProgressActivity from '@/Components/Icons/IconProgressActivity.vue';

const props = defineProps({
  type: {
    type: String,
    default: 'submit'
  },
  size: {
    type: String,
    default: 'medium'
  },
  isLoading: {
    type: Boolean
  }
});

const classes = getButtonSizeClasses(props.size);
</script>

<template>
  <button
    :type="type"
    :class="[
      classes,
      'hover:bg-opacity-80 disabled:bg-opacity-80 inline-flex cursor-pointer items-center rounded-full border border-transparent bg-blue-600 transition duration-300 ease-in-out focus:outline-hidden'
    ]"
    :disabled="isLoading"
  >
    <span class="flex grow items-center gap-3">
      <IconProgressActivity v-if="isLoading" :size="18" class="animate-spin" />
      <slot />
    </span>
  </button>
</template>
