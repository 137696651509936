<script setup>
import { Link } from '@inertiajs/vue3';
import ApplicationLogo from '@/Components/Logo/ApplicationLogo.vue';
import IconMenu from '@/Components/Icons/IconMenu.vue';
import { reactive } from 'vue';
import IconClose from '@/Components/Icons/IconClose.vue';

const state = reactive({
  isMobileMenuOpen: false
});

const emit = defineEmits(['toggleMobileMenu']);

const toggleMobileMenu = () => {
  state.isMobileMenuOpen = !state.isMobileMenuOpen;
  emit('toggleMobileMenu', state.isMobileMenuOpen);
};
</script>

<template>
  <div>
    <div class="flex items-center justify-between">
      <div class="dark flex items-center gap-4 md:gap-10">
        <Link tabindex="-1" :href="route('score.index')">
          <ApplicationLogo />
        </Link>
      </div>
      <button
        class="text-cool-grey-400 hover:bg-white-smoke-600 cursor-pointer rounded-sm p-2 focus:ring-3 md:hidden"
        @click="toggleMobileMenu"
      >
        <IconMenu v-if="!state.isMobileMenuOpen" />
        <IconClose v-else />
      </button>
    </div>
  </div>
</template>
