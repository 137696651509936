<template>
  <div class="relative h-[100px] w-[100px] rounded-full">
    <div
      class="clip bg-cool-grey-200 flex h-[100px] w-[100px] items-center justify-center rounded-full"
    >
      <div
        class="clip bg-cool-grey-300 absolute flex h-[84px] w-[84px] items-center justify-center rounded-full"
      ></div>
    </div>
    <div
      class="bg-cool-grey-400 text-white-smoke-500 absolute top-1/2 left-1/2 flex h-16 w-16 -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full text-2xl font-bold tracking-wide"
    >
      95
    </div>
  </div>
</template>

<style>
.clip {
  clip-path: polygon(
    50% 50%,
    60.5% 0%,
    100% 0%,
    100% 100%,
    0% 100%,
    0% 0%,
    51.25% 0%
  );
}
</style>
