<script setup>
import TextInput from '@/Components/Input/TextInput.vue';
import { reactive } from 'vue';
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import { IpAddress, IpRange } from 'cidr-calc';

import Card from '@/Components/Card/Card.vue';

const form = reactive({
  firstIp: '',
  lastIp: ''
});

const state = reactive({
  firstIp: null,
  lastIp: null,
  ranges: null
});

function submit() {
  state.firstIp = form.firstIp;
  state.lastIp = form.lastIp;
  state.ranges = null;

  try {
    state.ranges = new IpRange(
      IpAddress.of(state.firstIp),
      IpAddress.of(state.lastIp)
    )
      .toCidrs()
      .map(formatCidr);
    // eslint-disable-next-line
  } catch (e) {
    // no-op
  }
}

function formatCidr({ prefix, prefixLen }) {
  return `${prefix.bytes.join('.')}/${prefixLen}`;
}
</script>

<template>
  <form
    class="flex grow flex-col gap-4 md:flex-row md:items-center"
    @submit.prevent="submit"
  >
    <TextInput
      v-model.trim="form.firstIp"
      size="large"
      label="First IP Address"
      description="e.g. 127.0.0.0"
      placeholder="Enter First IP Address"
    />
    <TextInput
      v-model.trim="form.lastIp"
      size="large"
      label="Last IP Address"
      description="e.g. 127.0.0.128"
      placeholder="Enter Last IP Address"
    />
    <div class="flex items-center md:mt-2">
      <PrimaryButton size="large">Calculate</PrimaryButton>
    </div>
  </form>

  <Teleport v-if="state.ranges !== null" to="#results">
    <Card>
      <div class="flex flex-row gap-3">
        <div class="font-bold">
          The CIDRs for given IP range ({{ state.firstIp }} to
          {{ state.lastIp }}) are:
        </div>
        <div class="flex flex-col">
          <div
            class="font-normal"
            v-for="(range, key) in state.ranges"
            :key="key"
          >
            {{ range }}
          </div>
        </div>
      </div>
    </Card>
  </Teleport>
</template>
