<script setup>
import { onMounted, ref } from 'vue';
import InputLabel from '@/Components/Input/InputLabel.vue';
import InputDescription from '@/Components/Input/InputDescription.vue';
import Collapsable from '@/Components/Collapsable.vue';
import OutlinedButton from '@/Components/Button/OutlinedButton.vue';
import { newInputId } from '@/Common/utils.js';

const { collapsed } = defineProps({
  id: {
    type: String,
    default: () => newInputId()
  },
  description: {
    type: String,
    default: null
  },
  modelValue: String,
  label: {
    type: [String, Boolean],
    default: false
  },
  labelSize: {
    type: String,
    default: 'md'
  },
  labelClasses: {
    type: String,
    required: false
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  collapsed: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});

defineEmits(['update:modelValue', 'paste', 'focus', 'clear']);

const input = ref(null);

onMounted(() => {
  if (!collapsed && input.value.hasAttribute('autofocus')) {
    input.value.focus();
  }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
  <div>
    <InputLabel
      v-if="label"
      :for="id"
      :size="labelSize"
      :value="label"
      :class="labelClasses"
    />
    <Collapsable
      :collapsed="collapsed"
      :min-height="54"
      :class="{ 'overflow-hidden rounded-lg': collapsed }"
    >
      <div class="flex flex-col gap-4 sm:flex-row">
        <textarea
          v-bind="$attrs"
          :id="id"
          ref="input"
          class="border-white-smoke-600 bg-navy-100 hover:border-navy-200 flex w-full basis-4/5 rounded-lg px-5 py-4 leading-5 focus:bg-white focus:ring-0"
          :class="{
            'leading-7': !collapsed,
            'resize-none overflow-hidden hover:cursor-pointer': collapsed
          }"
          :value="collapsed ? modelValue.split('\n')?.[0] : modelValue"
          :placeholder="placeholder"
          @input="$emit('update:modelValue', $event.target.value)"
          @paste="$emit('paste', $event)"
          @focus="$emit('focus', $event)"
        />
        <OutlinedButton
          v-if="collapsed"
          @click="$emit('clear')"
          :is-loading="isLoading"
          class="basis-1/5 border-blue-500! text-center! text-blue-500!"
        >
          <span class="grow">Clear &amp; Reload</span>
        </OutlinedButton>
      </div>
    </Collapsable>
    <InputDescription v-if="description">
      {{ description }}
    </InputDescription>
  </div>
</template>
