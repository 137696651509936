<script setup>
import SiteLayout from '@/Layouts/Layout.vue';
import Card from '@/Components/Card/Card.vue';
import { reactive } from 'vue';
import { router } from '@inertiajs/vue3';
import LookupForm from '@/Pages/Lookup/Partials/LookupForm.vue';
import LookupResults from '@/Pages/Lookup/Partials/LookupResults.vue';
import Preloader from '@/Components/Preloader.vue';

const props = defineProps({
  rdType: String,
  domain: {
    type: String,
    default: ''
  },
  result: {
    type: Object,
    default: null
  }
});

const state = reactive({
  isLoading: false
});

const submit = (domain) => {
  state.isLoading = true;
  router.visit(route('lookup.show', [props.rdType, domain]));
};
</script>

<template>
  <SiteLayout
    title="DNS Lookup"
    description="Perform several DNS lookups here to see a variety of generic records run against the domain, including DMARC, SPF, and DKIM."
  >
    <Card>
      <LookupForm
        :domain="domain"
        :lookup-type="rdType.toUpperCase()"
        @submitted="submit"
      />
    </Card>
    <Card v-if="state.isLoading">
      <Preloader :rows="4" />
    </Card>
    <LookupResults v-else :result="result" />
  </SiteLayout>
</template>
