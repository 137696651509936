<script setup>
import { onMounted, ref } from 'vue';
import InputLabel from '@/Components/Input/InputLabel.vue';
import InputDescription from '@/Components/Input/InputDescription.vue';
import getInputSizeClasses from '@/Components/Input/sizing.js';

const props = defineProps({
  modelValue: [String, Number],
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  label: {
    type: [String, Boolean],
    default: false
  },
  labelSize: {
    type: String,
    default: 'md'
  },
  size: {
    type: String,
    default: 'medium'
  },
  description: {
    type: [String, Boolean],
    default: false
  },
  id: String
});

defineEmits(['update:modelValue']);

const input = ref(null);
const sizeClasses = getInputSizeClasses(props.size);

onMounted(() => {
  if (input.value.hasAttribute('autofocus')) {
    input.value.focus();
  }
});

defineExpose({ focus: () => input.value.focus() });

defineOptions({
  inheritAttrs: false
});
</script>

<template>
  <div class="flex flex-1 flex-col">
    <InputLabel v-if="label" :for="id" :size="labelSize" :value="label" />
    <select
      v-bind="$attrs"
      ref="input"
      class="border-white-smoke-600 bg-navy-100 hover:border-navy-200 rounded-lg focus:bg-white focus:ring-1"
      :class="sizeClasses"
      :value="modelValue"
      :placeholder="placeholder"
      @input="$emit('update:modelValue', $event.target.value)"
    >
      <slot />
    </select>
    <InputDescription v-if="description">
      {{ description }}
    </InputDescription>
  </div>
</template>
