<script setup>
import RatingSquareScore from '@/Pages/DomainScore/Partials/RatingSquareScore.vue';
import RiskLabel from '@/Pages/DomainScore/Partials/RiskLabel.vue';
import { inject } from 'vue';
import BimiCard from '@/Pages/DomainScore/Partials/DetailedReport/Branding/BimiCard.vue';
import ACTION_TYPES, {
  getActionTypeFromScore
} from '@/Common/Enum/ActionType.js';
import Help from '@/Components/Help.vue';

const result = inject('score');
const actionType = getActionTypeFromScore(result.marketing.score);
</script>

<template>
  <div>
    <div class="flex items-start gap-3">
      <div class="flex flex-col items-center">
        <RatingSquareScore :score="result.marketing.score" />
        <RiskLabel
          :score="result.marketing.score"
          class="mt-1 text-sm text-nowrap"
        >
          Risk
        </RiskLabel>
      </div>
      <div class="flex flex-col gap-2">
        <h5 class="flex items-center gap-2 text-lg font-bold text-white">
          Branding Rating
          <Help
            text="Your branding rating assesses whether your organization's domain branding has been correctly set up by reviewing your BIMI record."
          />
        </h5>
        <p v-if="actionType === ACTION_TYPES.NO_ACTION">
          Your organization uses email branding effectively, making your emails
          more trustworthy. Your logo and verified mark (if applicable) will be
          visible to recipients in email clients that support BIMI.
        </p>
        <p v-else-if="actionType === ACTION_TYPES.ACTION_RECOMMENDED">
          Your email branding settings need some tweaks. Your logo and verified
          mark (if applicable) may show in some email clients, but most require
          a full BIMI setup to display branding.
        </p>
        <p v-else>
          Right now, your branding isn't displayed with your emails. Branding
          builds trust and assures recipients that an email is authentic. We
          recommend full BIMI implementation for boosted recognition, visibility
          and trust.
        </p>
      </div>
    </div>
    <BimiCard />
  </div>
</template>
