<script setup>
import WebLayout from '@/Layouts/WebLayout.vue';
import PdfLayout from '@/Layouts/PdfLayout.vue';
import { inject } from 'vue';

defineProps({
  title: String,
  description: {
    type: String,
    default: ''
  }
});

const layout = inject('layout');
</script>

<template>
  <PdfLayout v-if="layout === 'pdf'" :title="title">
    <template #headerLogo>
      <slot name="headerRight" />
    </template>
    <slot />
  </PdfLayout>
  <WebLayout v-else :title="title" :description="description">
    <slot />
  </WebLayout>
</template>
