<script setup>
import Card from '@/Components/Card/Card.vue';
import ExpandablePill from '@/Components/ExpandablePill.vue';
import { computed, inject, reactive } from 'vue';
import Preloader from '@/Components/Preloader.vue';
import axios from 'axios';
import ExplainTitle from '@/Pages/DomainAnalysis/Partials/ExplainTitle.vue';
import ExplainDirectivePart from '@/Pages/DomainAnalysis/Partials/ExplainDirectivePart.vue';
import ExplainDirectiveCard from '@/Pages/DomainAnalysis/Partials/ExplainDirectiveCard.vue';
import ExplainBimiLogo from '@/Pages/DomainAnalysis/Partials/ExplainBimiLogo.vue';
import ExplainBimiCertificate from '@/Pages/DomainAnalysis/Partials/ExplainBimiCertificate.vue';
import { BIMI as BimiTags } from '@/Common/Classifiers/TagMaps.js';
import ExplainRecordValue from '@/Pages/DomainAnalysis/Partials/ExplainRecordValue.vue';

const domain = inject('domain');
const result = inject('result');
const record = result.records.bimi;

const state = reactive({
  result: null
});

const expand = async () => {
  if (!state.result) {
    state.result = (
      await axios.get(route('explain.show', ['bimi', domain]))
    ).data;
  }
};

const directives = computed(() => {
  return (
    state.result?.directives.reduce((acc, directive) => {
      acc[directive.directive] = directive;
      return acc;
    }, {}) ?? {}
  );
});

const bimiTags = Array.from(BimiTags.map(record.tags).values()).filter(
  (t) => t?.pill != null
);
</script>

<template>
  <Card>
    <ExplainTitle :pills="bimiTags" title="BIMI" />
    <div
      v-if="result.marketing.has_bimi_record"
      class="mt-2 flex flex-col gap-4"
    >
      <ExpandablePill
        v-for="recordValue in result.records.bimi.entries"
        :key="recordValue"
        :expandable="result.records.bimi.entries.length < 2"
        @click="expand"
      >
        <template #default="{ expanded }">
          <ExplainRecordValue
            :status="record.status"
            :record-value="recordValue"
            :expanded="expanded"
          />
        </template>
        <template #expandable>
          <Card
            v-if="!state.result && !Object.keys(directives).length > 0"
            class="bg-white-smoke-500 mt-2 text-sm"
          >
            <Preloader :rows="10" />
          </Card>
          <div v-else>
            <ExplainDirectiveCard>
              <ExplainDirectivePart title="Version" :directive="directives.v" />
              <ExplainDirectivePart title="Logo" :directive="directives.l" />
              <ExplainDirectivePart
                title="Authority"
                :directive="directives.a"
                info-text="Not Specified"
              />
            </ExplainDirectiveCard>
            <ExplainBimiLogo :record="record" />
            <ExplainBimiCertificate :record="record" />
          </div>
        </template>
      </ExpandablePill>
    </div>
  </Card>
</template>
