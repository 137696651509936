<script setup>
import Card from '@/Components/Card/Card.vue';
import { inject } from 'vue';
import ActionType from '@/Common/Enum/ActionType.js';
import ACTION_TYPES from '@/Common/Enum/ActionType.js';
import ExplainScorePart from '@/Pages/DomainScore/Partials/DetailedReport/ExplainScorePart.vue';
import LinkDomainAnalysis from '@/Pages/DomainScore/Partials/DetailedReport/LinkDomainAnalysis.vue';
import DetailedReportHeader from '@/Pages/DomainScore/Partials/DetailedReport/DetailedReportHeader.vue';

const result = inject('score');

const tags =
  result.records.dkim?.tags?.reduce((acc, tag) => {
    acc[tag.msg] = tag;
    return acc;
  }, {}) ?? {};

const recordMessage = (() => {
  const tagMessages = {
    'weak keys': {
      message: 'Weak DKIM Keys found',
      actionType: ActionType.ACTION_RECOMMENDED
    },
    'invalid keys': {
      message: 'Invalid DKIM Keys found',
      actionType: ActionType.ACTION_RECOMMENDED
    },
    'no keys': {
      message: 'No known DKIM Keys found',
      actionType: ActionType.INFO
    },
    'error keys': {
      message: 'DKIM Keys contain errors',
      actionType: ActionType.ACTION_RECOMMENDED
    },
    'no dkim no mx': {
      message: 'No MX records. DKIM not required.',
      actionType: ActionType.INFO
    },
    'large keys': {
      message: 'Large DKIM Keys found',
      actionType: ActionType.INFO
    },
    'no errors': {
      message: 'DKIM Keys found without errors',
      actionType: ActionType.NO_ACTION
    },
    'dkim wildcard': {
      message: 'DKIM Lookup contains wildcard response',
      actionType: ActionType.ACTION_RECOMMENDED
    }
  };

  let actionType = null;
  let messages = [];

  Object.keys(tags).forEach((tag) => {
    if (tagMessages.hasOwnProperty(tag)) {
      if (actionType === null) {
        actionType = tagMessages[tag].actionType;
      }
      messages.push(tagMessages[tag].message);
    }
  });

  if (!actionType) {
    return {
      actionType: ActionType.NO_ACTION,
      message: `DKIM Keys found without errors`
    };
  }

  return { actionType, message: messages.join('. ') };
})();
</script>

<template>
  <Card class="bg-white-opacity">
    <DetailedReportHeader
      title="DKIM"
      description="DomainKeys Identified Mail"
      tooltip="The DKIM record stores the public key used to verify any email signed with the corresponding private key, associating the message with the domain."
    />
    <div class="mt-5 flex flex-col">
      <ExplainScorePart
        v-if="
          tags.hasOwnProperty('no dkim no mx') &&
          !result.impersonation.num_dkim_records
        "
        title="DNS Record"
        :action-type="recordMessage.actionType"
      >
        No MX records. DKIM not required.
      </ExplainScorePart>
      <ExplainScorePart
        v-else-if="!result.impersonation.num_dkim_records"
        title="DNS Record"
        :action-type="ACTION_TYPES.INFO"
      >
        No known DKIM Keys found
      </ExplainScorePart>
      <ExplainScorePart
        v-else
        title="DNS Record"
        :action-type="recordMessage.actionType"
      >
        {{ recordMessage.message }} -
        <LinkDomainAnalysis :domain="result.domain" fragment="dkim" />
      </ExplainScorePart>
      <ExplainScorePart
        v-if="result.impersonation.num_dkim_records"
        title="Selectors"
        :action-type="ACTION_TYPES.INFO"
      >
        {{ result.impersonation.num_dkim_records }} Selectors found
      </ExplainScorePart>
    </div>
  </Card>
</template>
