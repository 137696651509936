<script setup>
import Card from '@/Components/Card/Card.vue';
import RdapDetail from '@/Pages/HeaderAnalysis/Partials/RdapDetail.vue';
import GeoDetail from '@/Pages/HeaderAnalysis/Partials/GeoDetail.vue';
import PropertyListItem from '@/Pages/HeaderAnalysis/Partials/PropertyListItem.vue';

defineProps({
  hop: Object
});

const hopHasRdapOrGeo = (hop) =>
  hop.from?.rdap || hop.from?.geo || hop.by?.rdap || hop.by?.geo;
</script>

<template>
  <Card class="bg-white-smoke-500!" :pad-bottom="true">
    <div class="flex flex-col gap-1">
      <PropertyListItem
        title="Sender"
        :value="hop.from.name"
        title-class-width="w-1/6"
      />
      <PropertyListItem
        title="Sender Comments"
        :value="hop.from.comments"
        :array-as-column="true"
        title-class-width="w-1/6"
      />
      <PropertyListItem
        title="Receiver"
        :value="hop.by.name"
        title-class-width="w-1/6"
      />
      <PropertyListItem
        title="Receiver Comments"
        :value="hop.by.comments"
        :array-as-column="true"
        title-class-width="w-1/6"
      />
      <PropertyListItem
        v-if="hop.id"
        title="Receiver ID"
        :value="hop.id"
        title-class-width="w-1/6"
      />
      <PropertyListItem
        v-if="hop.with"
        title="With (Protocol)"
        :value="hop.with"
        title-class-width="w-1/6"
      />
      <PropertyListItem
        v-if="hop.via"
        title="Via (Mechanism)"
        :value="hop.via"
        title-class-width="w-1/6"
      />
      <PropertyListItem
        title="Routing"
        :value="hop.routing"
        title-class-width="w-1/6"
        :value-class="['capitalize']"
      />
    </div>

    <div
      v-if="hopHasRdapOrGeo(hop)"
      class="mt-6 flex shrink-0 flex-col gap-6 md:flex-row"
    >
      <Card class="bg-cool-grey-500/10! md:w-1/2">
        <RdapDetail :rdap="hop.from.rdap" context-title="Sending" />
        <GeoDetail :geo="hop.from.geo" class="mt-6" />
      </Card>
      <Card class="bg-cool-grey-500/10! md:w-1/2">
        <RdapDetail :rdap="hop.by.rdap" context-title="Receiving" />
        <GeoDetail :geo="hop.by.geo" class="mt-6" />
      </Card>
    </div>
  </Card>
</template>
