<script setup>
import ACTION_TYPES, {
  getActionTypeFromScore
} from '@/Common/Enum/ActionType.js';

const props = defineProps({
  score: Number
});

const actionType = getActionTypeFromScore(props.score);
</script>

<template>
  <div class="mt-4">
    <p v-if="actionType === ACTION_TYPES.NO_ACTION">
      Your emails are secure from impersonation and interception, plus
      recipients will see your branding in communication. This builds trust in
      your brand, protects recipients from cyberattacks, and helps ensure
      effective email delivery.
    </p>
    <p v-else-if="actionType === ACTION_TYPES.ACTION_RECOMMENDED">
      You've got some measures in place to shield recipients from harmful emails
      coming from your domain. But there's opportunity to strengthen your
      domain's security even more. Taking these steps can boost trust in your
      brand, keep your business and stakeholders safe from cyberattacks, and
      help ensure emails are delivered effectively.
    </p>
    <p v-else>
      You don't have effective controls in place to protect your domain from
      impersonation and interception. This puts your brand and email recipients
      at risk of cyberattacks, which reduces trust and damages email
      deliverability.
    </p>
  </div>
</template>
