<template>
  <footer
    class="text-cool-grey-500 mt-8 mb-3 flex items-center justify-center gap-2"
  >
    Copyright &copy; Sendmarc
    <span>-</span>
    <a
      href="https://sendmarc.com"
      class="text-blue-600 hover:text-blue-500"
      target="_blank"
    >
      Website
    </a>
    <span class="text-cool-grey-500">|</span>
    <a
      href="https://sendmarc.com/privacy-policy/"
      class="text-blue-600 hover:text-blue-500"
      target="_blank"
    >
      Privacy Policy
    </a>
  </footer>
</template>
