<script setup>
import Card from '@/Components/Card/Card.vue';
import { inject } from 'vue';
import ACTION_TYPES from '@/Common/Enum/ActionType.js';
import LinkDomainAnalysis from '@/Pages/DomainScore/Partials/DetailedReport/LinkDomainAnalysis.vue';
import ActionType from '@/Common/Enum/ActionType.js';
import ExplainScorePart from '@/Pages/DomainScore/Partials/DetailedReport/ExplainScorePart.vue';
import { API_STATUS } from '@/Common/Enum/ApiStatus.js';
import DetailedReportHeader from '@/Pages/DomainScore/Partials/DetailedReport/DetailedReportHeader.vue';

const result = inject('score');

const tags =
  result.records.mtasts?.tags?.reduce((acc, tag) => {
    acc[tag.msg] = tag;
    return acc;
  }, {}) ?? {};
</script>

<template>
  <Card class="bg-white-opacity">
    <DetailedReportHeader
      title="MTA-STS"
      description="Mail Transfer Agents - Strict Transport Security"
      tooltip="Enables mail service providers to confirm their capability to receive TLS-secured SMTP connections."
    />
    <div class="mt-5 flex flex-col">
      <ExplainScorePart
        v-if="!result.privacy.has_mtasts_record"
        title="DNS Record"
        :action-type="ACTION_TYPES.ACTION_REQUIRED"
      >
        No record found
      </ExplainScorePart>
      <ExplainScorePart
        v-else-if="tags.hasOwnProperty('Too many records')"
        title="DNS Record"
        :action-type="ActionType.ACTION_REQUIRED"
      >
        Multiple records found -
        <LinkDomainAnalysis :domain="result.domain" fragment="mta" />
      </ExplainScorePart>
      <ExplainScorePart
        v-else-if="
          [API_STATUS.FAIL, API_STATUS.FATAL].includes(
            result.records?.tlsrpt?.status
          )
        "
        title="DNS Record"
        :action-type="ActionType.ACTION_REQUIRED"
      >
        Invalid record found -
        <LinkDomainAnalysis :domain="result.domain" fragment="mta" />
      </ExplainScorePart>
      <ExplainScorePart
        v-else-if="tags.hasOwnProperty('errors')"
        title="DNS Record"
        :action-type="ActionType.ACTION_REQUIRED"
      >
        Record contain errors -
        <LinkDomainAnalysis :domain="result.domain" fragment="mta" />
      </ExplainScorePart>
      <ExplainScorePart
        v-else
        title="DNS Record"
        :action-type="ActionType.NO_ACTION"
      >
        Record found without errors -
        <LinkDomainAnalysis :domain="result.domain" fragment="mta" />
      </ExplainScorePart>

      <div v-if="result.records?.mtasts?.status !== API_STATUS.FATAL">
        <ExplainScorePart
          v-if="!result.records.mtasts.has_policy"
          title="Policy"
          :action-type="ACTION_TYPES.ACTION_REQUIRED"
        >
          Policy contain errors
        </ExplainScorePart>

        <ExplainScorePart
          v-else-if="result.records.mtasts.policy_mode === 'enforce'"
          title="Policy"
          :action-type="ACTION_TYPES.NO_ACTION"
        >
          Policy set to 'Enforce'; encryption enforced.
        </ExplainScorePart>

        <ExplainScorePart
          v-else-if="result.records.mtasts.policy_mode === 'testing'"
          title="Policy"
          :action-type="ACTION_TYPES.ACTION_REQUIRED"
        >
          Policy set to 'testing'; will report on encryption failures.
        </ExplainScorePart>

        <ExplainScorePart
          v-else-if="result.records.mtasts.policy_mode === 'none'"
          title="Policy"
          :action-type="ACTION_TYPES.ACTION_REQUIRED"
        >
          Policy set to 'none'; encryption is not enforced.
        </ExplainScorePart>
      </div>
    </div>
  </Card>
</template>
