<script setup>
import { computed } from 'vue';

const { partnerLogo } = defineProps({
  partnerLogo: {
    type: [Object, null]
  }
});

const containerStyle = computed(() => ({
  width: partnerLogo.logo_width + 'px',
  height: partnerLogo.logo_height + 'px',
  ...(partnerLogo.logo_bg_color !== null && {
    backgroundColor: partnerLogo.logo_bg_color
  }),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));
</script>

<template>
  <div class="image-container" :style="containerStyle">
    <img
      :src="partnerLogo.logo_url"
      alt="Partner Logo"
      :height="partnerLogo.logo_height + 'px'"
      :width="partnerLogo.logo_width + 'px'"
    />
  </div>
</template>
