<script setup>
import { onMounted, onUnmounted, reactive } from 'vue';
import { ref } from 'vue';
import IconFileUpload from '@/Components/Icons/IconFileUpload.vue';
import InputLabel from '@/Components/Input/InputLabel.vue';
import InputDescription from '@/Components/Input/InputDescription.vue';
import IconPlaceItem from '@/Components/Icons/IconPlaceItem.vue';
import InputError from '@/Components/Input/InputError.vue';
import Collapsable from '@/Components/Collapsable.vue';
import { newInputId } from '@/Common/utils.js';

const emit = defineEmits(['files-added']);

const file = ref(null);

const props = defineProps({
  id: {
    type: String,
    default: () => newInputId()
  },
  dropText: {
    type: String,
    default: 'Drop Files'
  },
  fileTypes: Array,
  multiple: Boolean,
  sizeLimitKb: Number,
  label: {
    type: [String, Boolean],
    default: false
  },
  labelSize: {
    type: String,
    default: 'md'
  },
  labelClasses: {
    type: String,
    required: false
  },
  collapsed: {
    type: Boolean,
    default: false
  }
});

const state = reactive({
  dragging: false,
  errorMessage: ''
});

const accept = props.fileTypes.map((type) => `.${type}`).join(',');

const onDrop = (e) => {
  state.dragging = false;
  if (!e.dataTransfer.files.length) {
    return;
  }

  for (const file of e.dataTransfer.files) {
    if (file.size > props.sizeLimitKb * 1000) {
      state.errorMessage = `File size ${Math.round(file.size / 1000)} KB exceeds limit of ${props.sizeLimitKb} KB.`;
      console.warn('File too large, upload rejected.');
      return;
    }
  }

  file.value.files = e.dataTransfer.files;
  emit('files-added', file.value);
};

const onDrag = (e) => {
  e.preventDefault();
  state.errorMessage = null;

  if (e.type === 'dragover') {
    state.dragging = true;
  }
  if (e.type === 'drop') {
    state.dragging = false;
  }

  checkDraggedOut(e);
};

// Dragend is not reliable
const checkDraggedOut = (e) => {
  const x = e.clientX;
  const y = e.clientY;
  const buffer = 35;

  if (
    x - buffer < 0 ||
    y - buffer < 0 ||
    x >= window.innerWidth - buffer ||
    y >= window.innerHeight - buffer
  ) {
    state.dragging = false;
  }
};

const trackedEvents = ['dragover', 'drop'];

onMounted(() => {
  trackedEvents.forEach((eventName) => {
    document.body.addEventListener(eventName, onDrag);
  });
  document.body.addEventListener('drop', onDrop);
  document.body.addEventListener('mousemove', checkDraggedOut);
});

onUnmounted(() => {
  trackedEvents.forEach((eventName) => {
    document.body.removeEventListener(eventName, onDrag);
  });
  document.body.removeEventListener('drop', onDrop);
  document.body.removeEventListener('mousemove', checkDraggedOut);
});
</script>

<template>
  <Collapsable :collapsed="collapsed">
    <InputLabel
      v-if="label"
      :for="id"
      :size="labelSize"
      :value="label"
      :class="labelClasses"
    />
    <div
      class="border-navy-200 bg-navy-100 hover:border-navy-300 relative z-30 flex w-full cursor-pointer justify-center rounded-lg border border-dashed p-3 backdrop-blur-3xl"
      :class="{ 'bg-white': state.dragging }"
      @click="file.click()"
    >
      <div v-if="!$slots.default" class="flex items-center gap-2">
        <IconFileUpload v-if="!state.dragging" :size="20" />
        <IconPlaceItem v-else :size="20" />

        <div v-if="!state.dragging">
          <a class="underline">Browse</a>
          or Drag and Drop Files Here
        </div>
        <div v-else>
          {{ dropText }}
        </div>
      </div>
      <slot />
      <input
        ref="file"
        v-bind="$attrs"
        :multiple="multiple"
        :accept="accept"
        class="hidden"
        type="file"
        @change="() => emit('files-added', file)"
      />
    </div>
    <InputDescription>
      File Types Allowed: {{ fileTypes.join(', ') }} (Maximum file size of
      {{ sizeLimitKb }} KB)
    </InputDescription>
    <InputError v-if="state.errorMessage" :message="state.errorMessage" />
  </Collapsable>
  <div
    class="bg-navy-500/50 fixed top-0 left-0 z-20 h-full w-full backdrop-blur-[2px]"
    v-if="state.dragging"
  ></div>
</template>
